import { fetchAndSetPageData } from 'Pages/utils/page-data';

export default [
  {
    path: '/tax-center',
    name: 'tax-center',
    component: () => import(/* webpackChunkName: "PageTaxCenter" */ '../PageTaxCenter.vue'),
    async beforeEnter() {
      await fetchAndSetPageData('tax-center');
    },
  },
];
