import CONFIG from 'Config';
// eslint-disable-next-line import/no-cycle
import { IInvestmentListItem, IInvestmentListSummary, ITaxDocument, ITaxDocumentsRequest } from 'Models';
import { request } from '../request';
import { IListResponseData } from '../types';

export function fetchInvestmentListClosed(): Promise<IListResponseData<IInvestmentListItem>> {
  return request<IListResponseData<IInvestmentListItem>>(`${CONFIG.urls.investment}/?state=active&order=closed:desc`);
}

export function fetchInvestmentListSummary(): Promise<IInvestmentListSummary> {
  return request<IInvestmentListSummary>(`${CONFIG.urls.investment}/summary`);
}

export function getTaxDocuments(taxDocumentRequest: ITaxDocumentsRequest): Promise<ITaxDocument[]> {
  return request<ITaxDocument[]>(`${CONFIG.urls.investment}/documents/tax`, 'POST', taxDocumentRequest);
}

export function setTaxDocumentAsRead(taxDocumentId: string): Promise<void> {
  return request(`${CONFIG.urls.investment}/document/tax/${taxDocumentId}`);
}

export function bulkTaxDocDownload(taxDocumentRequest: ITaxDocumentsRequest): Promise<Blob> {
  return request(`${CONFIG.urls.investment}/documents/tax/bulk_download`, 'POST', taxDocumentRequest, false);
}
