import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  role: "progressbar",
  "aria-valuemin": "0",
  "aria-valuemax": "100",
  "aria-progressbar-name": "progressbar",
  "aria-label": "Base Spinner",
  class: "spinner__container"
}
const _hoisted_2 = ["viewBox"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["BaseSpinner spinner", { 'is--opaque': _ctx.opaque, 'is--full': _ctx.full, 'is--small': _ctx.small, }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createElementBlock("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: _ctx.svgViewBox,
            style: {"transform":"rotate(0deg)"}
          }, [
            _createElementVNode("circle", _mergeProps({
              fill: "transparent",
              r: "20",
              "stroke-dasharray": "125.664",
              "stroke-dashoffset": "125.66370614359172px",
              class: "spinner__overlay"
            }, _ctx.circleSizeAttrs), null, 16)
          ], 8, _hoisted_2)),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "spinner__info" }, null, -1))
        ])
      ], 2), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 1
  }))
}