import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, withModifiers as _withModifiers, mergeProps as _mergeProps, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal__header-title"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "modal__content" }

import {
  ref,
  watch,
  computed,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
  Ref,
  useSlots,
} from 'vue';
import { generateIdShort } from 'Utils';

// const
const BODY_SCROLL_LOCK_CLASS = 'is--modal-open';

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseModal',
  props: {
    show: { type: Boolean },
    class: {},
    size: { default: 'full' },
    hideCloseButton: { type: Boolean },
    noAnimationOnOpen: { type: Boolean },
    solidHeader: { type: Boolean },
    singleModal: { type: Boolean }
  },
  emits: ["update:show", "close"],
  setup(__props: any, { emit: __emit }) {

const slots = useSlots();

const props = __props;

const emit = __emit;

// data
const id = generateIdShort();
const bodyScrollLockClassWithId = `${BODY_SCROLL_LOCK_CLASS}--${id}`;
const isShow = ref(false);
const modal: Ref<null | HTMLElement> = ref(null);

// computed
const isFull = computed(() => props.size === 'full');
const isLarge = computed(() => props.size === 'large');
const isSmall = computed(() => props.size === 'small');

// methods
function updateEmit() {
  emit('update:show', isShow.value);
  if (!isShow.value) emit('close');
}

/**
 * Clear all body scroll lock classes in html element
 */
function clearAllBodyScrollLock() {
  const html = document.getElementsByTagName('html')[0];
  html.classList.forEach((className) => {
    if (className.startsWith(BODY_SCROLL_LOCK_CLASS)) html.classList.remove(className);
  });
}

function enableBodyScrollLock() {
  const html = document.getElementsByTagName('html')[0];
  html.classList.add(bodyScrollLockClassWithId);
}

function disableBodyScrollLock() {
  const html = document.getElementsByTagName('html')[0];
  html.classList.remove(bodyScrollLockClassWithId);
}

function closeModal() {
  isShow.value = false;
  setTimeout(updateEmit, 200);
}
// watch
watch(
  () => props.show,
  (show) => {
    isShow.value = show;
  },
);

// set isShow before mount if no animation
if (props.noAnimationOnOpen) {
  onBeforeMount(() => {
    isShow.value = props.show;
  });
}
onMounted(() => {
  isShow.value = props.show;
  // Clear all body scroll lock classes before enable new one if this modal can be open only one
  if (props.singleModal) clearAllBodyScrollLock();
  enableBodyScrollLock();
});

onBeforeUnmount(() => {
  disableBodyScrollLock();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (isShow.value)
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, _ctx.$attrs, {
            class: ["BaseModal modal modal__backdrop", [{
        'is--full': isFull.value,
        'is--small': isSmall.value,
        'is--large': isLarge.value,
        'is--no-header': !_unref(slots).title,
      }, _ctx.$props.class]],
            onClick: _withModifiers(closeModal, ["self"])
          }), [
            _createElementVNode("div", {
              ref_key: "modal",
              ref: modal,
              "aria-labelledby": "modal-headline",
              "aria-modal": "true",
              class: "modal__container",
              role: "dialog",
              onKeydown: _withKeys(closeModal, ["esc"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([{'modal__header-solid': _ctx.solidHeader},'modal__header'])
              }, [
                (_unref(slots).title)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [
                      _renderSlot(_ctx.$slots, "title")
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.hideCloseButton)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      "aria-label": "Close modal",
                      class: "modal__close-button",
                      type: "button",
                      onClick: closeModal
                    }, [
                      _createElementVNode("span", {
                        alt: "close-icon",
                        class: "svg-icon modal__close-button-icon",
                        innerHTML: require(`!!raw-loader!images/svg-icons/fa-close.svg`).default
                      }, null, 8, _hoisted_2)
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ], 544)
          ], 16))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})