import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "message" }

import { BaseModal } from 'Components/Base/BaseModal';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalOptOutConfirmed',
  props: {
  message: {
    type: String,
    required: true,
  },
},
  setup(__props) {



function onCancel() {
  // Reload the page to unload the segment scripts now that the user has opted out.
  window.location.reload();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseModal), {
    class: "ModalLogin modal-login",
    show: "",
    size: "small",
    onClose: onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(__props.message), 1),
        _createElementVNode("button", {
          class: "button",
          onClick: onCancel
        }, " Close ")
      ])
    ]),
    _: 1
  }))
}
}

})