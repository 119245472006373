import { ITaxDocumentsRequest, ITaxDocument } from '@/models';
import CONFIG from 'Config/index';
import { getTaxDocuments, bulkTaxDocDownload, setTaxDocumentAsRead, request } from '@/services/api';
import { shallowReadonly, reactive } from 'vue';
import { saveAs } from 'file-saver';

function getInitialTaxDocRequest(): ITaxDocumentsRequest {
  return {
    year: undefined,
    sorting_columns: undefined,
  };
}

const taxDocRequest = reactive(getInitialTaxDocRequest());

export function useGetTaxDocuments() {
  const taxDocuments = reactive<ITaxDocument[]>([]);

  async function getTemporaryToken() {
    const data = await request<{ token: string }>(`${CONFIG.urls.user}/temporary_token`);
    return data.token;
  }

  function updateDocRequest(newRequest: ITaxDocumentsRequest) {
    Object.assign(taxDocRequest, newRequest);
  }

  function resetDocRequest() {
    Object.assign(taxDocRequest, getInitialTaxDocRequest());
  }

  async function loadTaxDocuments() {
    const result = await getTaxDocuments(taxDocRequest);
    taxDocuments.splice(0, taxDocuments.length, ...result); // Clear and replace with new data
    return taxDocuments;
  }

  async function bulkTaxDocumentDownload() {
    const zipFile = await bulkTaxDocDownload(taxDocRequest);
    saveAs(zipFile, 'tax_documents.zip');
  }

  async function downloadTaxDocumentLink(url: string) {
    const token = await getTemporaryToken();
    return `${url}?token=${token}`;
  }

  return {
    taxDocuments,
    loadTaxDocuments,
    updateDocRequest,
    resetDocRequest,
    taxDocRequest: shallowReadonly(taxDocRequest),
    bulkTaxDocumentDownload,
    setTaxDocumentAsRead,
    downloadTaxDocumentLink,
  };
}
